import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { Section } from "../index";
import { HUBSPOT_FORM_SOURCE } from "../../hubspot-form/enums";
import {
  Wrapper,
  FormSidebar,
  Subtitle,
  SectionDescription,
  Image,
  Description,
  PhoneNumberLink,
  Form,
  CtaWrapper,
  CtaDescription,
  CtaTitle,
  CtaTitleMobile,
  CtaDescriptionMobile,
  CtaDescriptionDesktop
} from "./styles";
import EditorJSContent from "../../editor-js-content";

const HubspotForm = dynamic(() => import("../../hubspot-form/hubspot-form"));
const Link = dynamic(() => import("../../elements/link/link"));

export const InPageForm = ({ id, form, newVersion }) => {
  const {
    __typename,
    sectionTitle,
    sectionSubtitle,
    sectionDescription,
    hideLineSeparation,
    subtitle,
    description,
    image,
    ctaTitle,
    ctaDescription,
    ctaDescriptionDesktop,
    cta,
    descriptionPhoneNumberLink,
    backgroundColor,
    ctaTitleMobile,
    ctaDescriptionMobile
  } = form;

  const hasCtaWrapper = ctaTitle || cta;
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      id="form"
      backgroundColor={backgroundColor}
      hideLine={hideLineSeparation}
    >
      {sectionDescription && (
        <SectionDescription>
          <EditorJSContent content={sectionDescription} />
        </SectionDescription>
      )}
      <Wrapper newVersion={newVersion}>
        <FormSidebar newVersion={newVersion}>
          {image && <Image media={image} />}
          {hasCtaWrapper && (
            <CtaWrapper>
              {ctaTitle && <CtaTitle>{renderLineBreaks(ctaTitle)}</CtaTitle>}
              {newVersion && <hr />}
              {ctaDescriptionDesktop ? (
                <CtaDescriptionDesktop>
                  <EditorJSContent content={ctaDescriptionDesktop} />
                </CtaDescriptionDesktop>
              ) : ctaDescription && (
                <CtaDescription>
                  {renderLineBreaks(ctaDescription)}
                </CtaDescription>
              )}
            </CtaWrapper>
          )}
        </FormSidebar>
        <Form newVersion={newVersion}>
          {subtitle && <Subtitle newVersion={newVersion}>{renderLineBreaks(subtitle)}</Subtitle>}
          {description && (
            <Description newVersion={newVersion}>
              {renderLineBreaks(description)}
              {descriptionPhoneNumberLink && (
                <PhoneNumberLink>
                  <Link
                    type="text"
                    text={descriptionPhoneNumberLink}
                    href={cta.href}
                    target={cta.target}
                  />
                </PhoneNumberLink>
              )}
            </Description>
          )}
          <HubspotForm
            id={`${__typename}${id}`}
            form={form}
            source={HUBSPOT_FORM_SOURCE.landing}
          />
        </Form>
        {hasCtaWrapper && (
          <CtaWrapper isMobile>
            {newVersion ? ctaTitleMobile && (<CtaTitleMobile><EditorJSContent content={ctaTitleMobile} /></CtaTitleMobile>)
              :
              ctaTitle && <CtaTitle>{renderLineBreaks(ctaTitle)}</CtaTitle>}
            {newVersion && ctaDescriptionMobile && (
              <CtaDescriptionMobile>
                <EditorJSContent content={ctaDescriptionMobile} />
              </CtaDescriptionMobile>
            )}
            {cta && <Link type="buttonCta" {...cta} />}
          </CtaWrapper>
        )}
      </Wrapper>
    </Section>
  );
};

export default InPageForm;
